<template>
    <EditProject :showEdit="showEdit" :toggleEditWindow="toggleEditWindow" :project="thisProject" :editAssessments="editAssessments" :showNotif="showNotif" />
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgColor" />
    <div v-if="showDetails" @click="toggleMenu" class="w-[100%] h-[100%] fixed top-0 left-0 right-0 bottom-0" style="z-index: 10; background: transparent"></div>

    <div v-if="showArchive" class="archive">
        <div>
            <button class="w-[35px] h-[35px] flex justify-center items-center border-2 rounded-[50%] ml-[70%]" @click="toggleArchive">
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                <font-awesome-icon :icon="['fas', 'xmark']" class="text-[18px] text-red-500" />
            </button>
            <p
                v-html="
                    $t('type to activate project', {
                        projectName: thisProject.name,
                        action: thisProject.project_status === 'Active' ? $t('Archive') : $t('Activate'),
                    })
                "
            ></p>
            <div class="searchbar shadow-card">
                <img loading="lazy" decoding="async" src="../assets/Images/icons/Archeive.svg" alt="search-icon" />
                <input type="text" placeholder="Project title" v-model="archiveProjectName" />
            </div>
            <div class="btn_wrapper">
                <button @click="archiveProject">
                    {{ thisProject.project_status === "Active" ? $t("Archive") : $t("Activate") }}
                </button>
            </div>
        </div>
    </div>
    <div class="archive" v-if="showDelete">
        <div>
            <button class="w-[35px] h-[35px] flex justify-center items-center border-2 rounded-[50%] ml-[70%]" @click="toggleDelete">
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                <font-awesome-icon :icon="['fas', 'xmark']" class="text-[18px] text-red-500" />
            </button>
            <p
                v-html="
                    $t('type to delete project', {
                        projectName: thisProject.name,
                    })
                "
            ></p>
            <div class="searchbar centered shadow-card">
                <img loading="lazy" decoding="async" src="../assets/Images/icons/Archeive.svg" alt="search-icon" />
                <input type="text" placeholder="Project title" v-model="archiveProjectName" />
            </div>
            <div class="btn_wrapper">
                <button class="delete" @click="deleteProject">
                    {{ $t("Delete") }}
                </button>
            </div>
        </div>
    </div>
    <div class="menu bg-white absolute my-2 top-3/4 right-[90%] flex flex-col justify-between z-[150] w-52 h-fit rounded border border-gray-300 shadow-card" v-if="showDetails">
        <div class="menuItem w-full flex flex-row text-slate-700 px-4 justify-between font-light text-sm py-3 mb-1 overflow-hidden" :class="{ 'hover:bg-gray-200': !editable }" @click.stop>
            <div @click="toggleEditWindow" class="w-full flex justify-between items-center" v-if="editable">
                <p>{{ $t("Edit") }}</p>
                <!-- <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.5291 4.09283C16.8757 3.74622 16.8757 3.16852 16.5291 2.83967L14.4494 0.759964C14.1205 0.413345 13.5428 0.413345 13.1962 0.759964L11.5609 2.3864L14.8938 5.71927L16.5291 4.09283ZM0.789062 13.1671V16.5H4.12193L13.9517 6.66137L10.6188 3.3285L0.789062 13.1671Z"
                    />
                </svg> -->
                <font-awesome-icon :icon="['fas', 'pen']" class="w-5 h-5" />
            </div>
            <Popper v-else class="w-[150%]" :content="$t(`You can't edit an assessment after inviting candidates.`)" placement="left" :hover="true">
                <div class="w-full" :class="{ 'opacity-[0.5] cursor-[not-allowed] ': !editable }">
                    <div class="w-full flex justify-between items-center">
                        <p>{{ $t("Edit") }}</p>
                        <!-- <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.5291 4.09283C16.8757 3.74622 16.8757 3.16852 16.5291 2.83967L14.4494 0.759964C14.1205 0.413345 13.5428 0.413345 13.1962 0.759964L11.5609 2.3864L14.8938 5.71927L16.5291 4.09283ZM0.789062 13.1671V16.5H4.12193L13.9517 6.66137L10.6188 3.3285L0.789062 13.1671Z"
                            />
                        </svg> -->
                        <font-awesome-icon :icon="['fas', 'pen']" class="w-5 h-5 text-gray-500" />
                    </div>
                </div>
            </Popper>
        </div>
        <div class="menuItem w-full flex flex-row text-slate-700 px-4 justify-between font-light text-sm py-3 my-1" @click.stop @click="navigateToPreview">
            <p>{{ $t("Preview") }}</p>
            <font-awesome-icon :icon="['fas', 'eye']" class="w-5 h-5" />
        </div>
        <div class="menuItem w-full flex flex-row text-slate-700 px-4 justify-between font-light text-sm py-3 my-1" @click.stop @click="copyPreviewLink">
            <p>{{ $t("Copy preview Link") }}</p>
            <font-awesome-icon :icon="['fas', 'copy']" class="w-5 h-5" />
        </div>
        <div class="menuItem w-full flex flex-row text-slate-700 px-4 justify-between font-light text-sm py-3 my-1" @click.stop @click="duplicateProject">
            <p>{{ $t("Duplicate") }}</p>
            <!-- <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.73024 13.6C5.2773 13.6 4.88969 13.4337 4.56742 13.1011C4.24459 12.7679 4.08318 12.3675 4.08318 11.9V1.7C4.08318 1.2325 4.24459 0.83215 4.56742 0.49895C4.88969 0.166317 5.2773 0 5.73024 0H13.142C13.5949 0 13.9828 0.166317 14.3057 0.49895C14.6279 0.83215 14.7891 1.2325 14.7891 1.7V11.9C14.7891 12.3675 14.6279 12.7679 14.3057 13.1011C13.9828 13.4337 13.5949 13.6 13.142 13.6H5.73024ZM5.73024 11.9H13.142V1.7H5.73024V11.9ZM2.43612 17C1.98318 17 1.5953 16.8337 1.27247 16.5011C0.9502 16.1679 0.789062 15.7675 0.789062 15.3V4.25C0.789062 4.00917 0.868121 3.80715 1.02624 3.64395C1.18381 3.48132 1.37926 3.4 1.61259 3.4C1.84593 3.4 2.04165 3.48132 2.19977 3.64395C2.35734 3.80715 2.43612 4.00917 2.43612 4.25V15.3H10.6714C10.9047 15.3 11.1005 15.3816 11.2586 15.5448C11.4162 15.7074 11.4949 15.9092 11.4949 16.15C11.4949 16.3908 11.4162 16.5926 11.2586 16.7552C11.1005 16.9184 10.9047 17 10.6714 17H2.43612ZM5.73024 1.7V11.9V1.7Z"
                />
            </svg> -->

            <font-awesome-icon :icon="['fas', 'copy']" class="w-5 h-5" />
        </div>
        <div class="menuItem w-full flex flex-row text-slate-700 px-4 justify-between font-light text-sm py-3 my-1" @click.stop @click="toggleArchive">
            <p>
                {{ thisProject.project_status === "Active" ? $t("Archive") : $t("Activate") }}
            </p>
            <!-- <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.4375 18.5714C1.4375 19.3616 2.07988 20 2.875 20H20.125C20.9201 20 21.5625 19.3616 21.5625 18.5714V5.71429H1.4375V18.5714ZM8.625 9.10714C8.625 8.8125 8.86758 8.57143 9.16406 8.57143H13.8359C14.1324 8.57143 14.375 8.8125 14.375 9.10714V9.46429C14.375 9.75893 14.1324 10 13.8359 10H9.16406C8.86758 10 8.625 9.75893 8.625 9.46429V9.10714ZM21.5625 0H1.4375C0.642383 0 0 0.638393 0 1.42857V3.57143C0 3.96429 0.323437 4.28571 0.71875 4.28571H22.2812C22.6766 4.28571 23 3.96429 23 3.57143V1.42857C23 0.638393 22.3576 0 21.5625 0Z"
                />
            </svg> -->
            <font-awesome-icon :icon="['fas', 'box-archive']" class="w-5 h-5" />
        </div>
        <div class="menuItem w-full flex flex-row text-slate-700 px-4 justify-between font-light text-sm py-3 mt-1" @click.stop @click="toggleDelete">
            <p>{{ $t("Delete") }}</p>
            <font-awesome-icon :icon="['fas', 'trash-can']" class="w-5 h-5 text-[#ff0000]" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import EditProject from "@/components/EditProject";
import ToastNotification from "@/components/ToastNotification";

export default {
    name: "ProjectMenu",
    props: {
        showDetails: Boolean,
        thisProject: Object,
        toggleMenu: Function,
        editable: Boolean,
    },
    components: {
        EditProject,
        ToastNotification,
    },
    data() {
        return {
            showArchive: false,
            showDelete: false,
            archiveProjectName: "",
            message: "",
            bgColor: "red",
            isVisible: false,
            showEdit: false,
            showNotif: false,
        };
    },
    methods: {
        navigateToPreview() {
            this.toggleMenu();
            const url = this.$router.resolve({
                path: "/preview-project",
                query: { id: this.thisProject._id },
            }).href;

            // Open the route in a new tab
            window.open(url, "_blank");
        },
        copyPreviewLink() {
            this.toggleMenu();
            const url = this.$router.resolve({
                path: "/preview-project",
                query: { id: this.thisProject._id },
            }).href;

            navigator.clipboard
                .writeText(`https://go-platform.com${url}`)
                .then(() => {
                    console.log("URL copied to clipboard:", url);
                })
                .catch((err) => {
                    console.error("Failed to copy URL to clipboard:", err);
                });
        },
        editAssessments() {
            this.$router.push({
                path: "/library",
                query: { id: this.thisProject._id },
            });
        },
        toggleEditWindow() {
            this.$router.push({
                path: "/newAssessment",
                query: { id: this.thisProject._id },
            });
            this.toggleMenu();
        },

        toggleArchive() {
            this.showArchive = !this.showArchive;
            this.toggleMenu();
        },
        toggleDelete() {
            this.showDelete = !this.showDelete;
            this.toggleMenu();
        },
        duplicateProject() {
            let data = JSON.stringify({
                id: this.thisProject._id,
            });

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/projects/duplicateProject`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
                withCredentials: true,
            };
            axios
                .request(config)
                .then((res) => {
                    this.message = res.data.message;
                    this.bgColor = "green";
                    this.$router.go();
                    this.isVisible = true;
                    setTimeout(() => {
                        this.isVisible = false;
                    }, 5000);
                    // this.$emit("archiveProject", this.project._id);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        archiveProject() {
            if (this.archiveProjectName === this.thisProject.name) {
                let data = JSON.stringify({
                    id: this.thisProject._id,
                });

                let config = {
                    method: "put",
                    maxBodyLength: Infinity,
                    url: `https://server.go-platform.com/projects/switchStatus`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };
                axios
                    .request(config)
                    .then((res) => {
                        console.log(res);
                        // this.thisProject = res.data;
                        this.toggleArchive();
                        this.$router.go();
                        // this.showDetails = !this.showDetails;
                        this.archiveProjectName = "";
                        // this.$emit("archiveProject", this.project._id);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.message = "Project name does not match";
                this.isVisible = true;
                setTimeout(() => {
                    this.isVisible = false;
                }, 5000);
            }
        },
        deleteProject() {
            if (this.archiveProjectName === this.thisProject.name) {
                let data = JSON.stringify({
                    id: this.thisProject._id,
                });

                let config = {
                    method: "delete",
                    maxBodyLength: Infinity,
                    url: `https://server.go-platform.com/projects/deleteProject`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                    withCredentials: true,
                };
                axios
                    .request(config)
                    .then((res) => {
                        console.log(res);
                        this.message = res.data.message;
                        this.isVisible = true;
                        this.toggleDelete();
                        // this.showDetails = !this.showDetails;
                        this.archiveProjectName = "";
                        this.$router.go();
                        setTimeout(() => {
                            this.isVisible = false;
                        }, 5000);
                        // this.$emit("archiveProject", this.project._id);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.message = "Project name does not match";
                this.isVisible = true;
                setTimeout(() => {
                    this.isVisible = false;
                }, 5000);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.archive {
    position: fixed;
    z-index: 100;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #0000003f;
    align-items: center;

    > :first-child {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        width: 550px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        p {
            span {
                color: #2196f3;
            }
        }
    }
}

.menu {
    position: absolute;
    // left: 101%;
    //top: 75%;
    //right: 90%;
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
    //z-index: 150;
    //width: 123px;
    //height: fit-content;

    //border-radius: 10px;
    //border-top-right-radius: 0px;
    //border: 1px solid #ccd7ec;
    //background: #fff;
    //box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);

    .menuItem {
        //width: 100%;
        //display: flex;
        //flex-direction: row;
        //justify-content: space-between;
        //color: #2b3679;
        //padding: 8px;
        transition: all 0.1s ease-in-out;

        svg {
            fill: #2b3679;
        }

        &:hover {
            cursor: pointer;
            color: white;
            background-color: #2196f3;

            p {
                color: #fff;
            }

            svg {
                fill: #fff;
            }
        }

        //p {
        //    color: #0f172a;
        //    font-family: Poppins;
        //    font-size: 16px;
        //    font-style: normal;
        //    font-weight: 500;
        //    line-height: normal;
        //}
    }

    > :last-child {
        // margin-bottom: 30px;
        p {
            color: #ff0000;
        }
    }
}

.trash-btn:hover {
    border: 2px solid red;
}

.btn_wrapper {
    height: 40px;
    width: 20%;
    margin: 0 30%;
    margin-left: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    bottom: 0%;

    button {
        width: 100%;
        // height: 0%;
        border-radius: 10px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        cursor: pointer;

        // &:first-child {
        //     background-color: #fff;
        //     color: #2196f3;
        // }
        &:last-child {
            background-color: #2196f3;
        }
    }
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    // box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 8px;
    padding: 0 8px;
    height: 45px;
    width: 70%;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #adb8cc;
}

input {
    border: none;
    height: 100%;
    width: 100%;
    margin-left: 10px;
}

input:focus {
    outline: none;
}

.fadded {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>
