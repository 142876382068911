<template>
    <UpdateCustomQuestion :isShown="isShown" :question_id="assessement._id" :closePanel="closePanel" :type="question_type" />
    <ConfirmDeleteQuestion :closeConfirm="closeConfirm" :isShown="isShownDelete" :id="assessement._id" />
    <div class="top-assessement w-full">
        <div class="relative flex flex-col items-start justify-between h-full w-full px-3 py-3 bg-white">
            <div class="topAssess w-full">
                <div class="flex relative justify-between">
                    <div class="flex gap-0 border-[#2196f3] pb-1 flex-col justify-center items-start w-full">
                        <h2
                            class="title-question w-full text-lg font-semibold text-left relative pb-4 mb-4 after:content-('') after:absolute after:bottom-0 after:left-0 after:w-full after:h-px after:bg-NeonBlue"
                        >
                            {{ assessement.name }}
                        </h2>
                        <div class="flex justify-center gap-4 items-center mb-4">
                            <span class="text-sm text-NeonBlue font-normal">{{ assessement.category }}</span>
                            <span class="text-sm text-NeonBlue font-normal">|</span>
                            <span class="text-sm text-NeonBlue font-normal">{{ assessement.time }} mins</span>
                            <span class="text-sm text-NeonBlue font-normal">|</span>
                            <span class="text-sm text-NeonBlue font-normal"> {{ !assessement.UserId ? "Go Platform" : "My library" }} </span>
                        </div>
                    </div>
                    <div class="absolute top-0 right-0">
                        <font-awesome-icon
                            v-if="assessement.UserId"
                            @click.stop="isOpen = !isOpen"
                            class="cursor-pointer rounded-full w-5 h-5 hover:bg-[#E0E0E0] p-1 rotate-90"
                            :icon="['fas', 'ellipsis']"
                        />
                    </div>
                    <div v-if="isOpen" ref="container" class="absolute -right-12 p-2 bottom-[20%] rounded-md w-25 flex bg-white border border-gray-200 flex-col gap-2 z-10">
                        <font-awesome-icon @click="defineType(assessement.category)" class="cursor-pointer hover:bg-[#E0E0E0] rounded-md p-1" :icon="['far', 'pen-to-square']" />
                        <font-awesome-icon @click="isShownDelete = true" class="cursor-pointer rounded-md hover:bg-[#E0E0E0] p-1" :icon="['far', 'trash-can']" />
                    </div>
                </div>
                <p class="text-sm leading-4 font-light py-4 text-left">{{ getFirstPhrase(assessement.question) }}</p>
            </div>
            <div class="w-full flex justify-end gap-2 items-center relative">
                <button
                    class="bg-black/5 text-gray-700 hover:bg-black/10 inline-flex items-center justify-center text-m rounded min-h-[30px] px-4 py-[10px] font-semibold"
                    v-if="!showedMore"
                    @click="showedMore = true"
                >
                    Show more
                </button>
                <button
                    class="bg-black/5 text-gray-700 hover:bg-black/10 inline-flex items-center justify-center text-m rounded min-h-[30px] px-4 py-[10px] font-semibold"
                    v-else
                    @click="showedMore = false"
                >
                    Show less
                </button>
                <button
                    v-if="!verifyAddedQuestion"
                    class="bg-[#2196f3] text-white hover:bg-[#1976d2] inline-flex shadow items-center justify-center text-m rounded w-[4rem] min-h-[30px] px-4 py-[10px] font-semibold flex items-center justify-center relative cursor-pointer"
                    @click="add(assessement)"
                >
                    {{ $t("Add now") }}
                </button>
                <button
                    v-else
                    class="bg-red-600 text-gray-700 hover:bg-red-700 inline-flex shadow items-center justify-center w-[4rem] text-m rounded min-h-[30px] px-5 py-[13px] font-semibold"
                    @click="deleteSelectedQst(addedQuestionId)"
                >
                    <font-awesome-icon class="text-white" :icon="['fas', 'xmark']" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import UpdateCustomQuestion from "@/components/UpdateCustomQuestion";
import ConfirmDeleteQuestion from "@/components/ConfirmDeleteQuestion";
export default {
    name: "QuestionCard",
    components: {
        UpdateCustomQuestion,
        ConfirmDeleteQuestion,
    },
    props: ["assessement", "addAssessment", "selectedQuestions", "deleteSelectedQst"],
    data() {
        return {
            imagePath: "",
            selected: false,
            showedMore: false,
            isOpen: false,
            isShown: false,
            isShownDelete: false,
        };
    },
    computed: {
        verifyAddedQuestion() {
            return !!this.selectedQuestions.find((question) => question.question === this.assessement?.question);
        },
        addedQuestionId() {
            const selectedQuestion = this.selectedQuestions.find((question) => question.question === this.assessement.question);
            return selectedQuestion ? selectedQuestion.id : null;
        },
    },

    methods: {
        defineType(type) {
            this.question_type = type;
            this.isShown = true;
        },
        closeConfirm() {
            this.isShownDelete = false;
        },
        closePanel() {
            this.isShown = false;
        },
        add(assessment) {
            this.addAssessment(assessment);
        },

        displayselected() {
            console.log("selected questions from question card: ", this.selectedQuestions);
        },
        getFirstPhrase(description) {
            if (!this.showedMore) {
                // Split the string into an array of words
                let words = description?.split(/\s+/);

                // Extract the first 200 words
                let first30Words = words?.slice(0, 30)?.join(" ");
                if (words.length < 30) return first30Words;
                first30Words += "...";
                return first30Words;
            }
            return description;
        },
        handleClickOutside(event) {
            if (this.$refs.container && !this.$refs.container.contains(event.target)) {
                this.isOpen = false;
            }
        },
    },
    mounted() {
        console.log("selected questions: " + this.selectedQuestions);
        window.addEventListener("click", this.handleClickOutside);
    },
    unmounted() {
        window.removeEventListener("click", this.handleClickOutside);
    },
};
</script>

<style lang="scss" scoped>
.top-assessement {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fff;
    border-radius: 10px;
    // box-shadow: 0px 0px 10px 2px rgba(195, 195, 195, 0.8);
    padding: 0.2rem;
    gap: 2rem;
    padding-bottom: 1rem;
    // height: 300px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow:
        20px 20px 60px #bebebe33,
        -20px -20px 60px #ffffff3a;
    transition: all 0.3s ease;
    height: 300px;
    // &:hover {
    //     height: 340px;
    //     // transform: scale(1.05);
    //     // .img-container {
    //     //     .hover-text {
    //     //         opacity: 1;
    //     //         background-color: rgba(255, 255, 255, 0.8);
    //     //     }
    //     // }
    //     .add-now {
    //         transition: all 0.3s ease;
    //         opacity: 1;
    //         // height: 50px;
    //         max-height: 50px;
    //     }
    //     .img-container {
    //         transition: all 0.3s ease;
    //         // opacity: 1;
    //         // max-height: 100px;
    //         margin-top: -5%;
    //     }
    //     .assessement-name {
    //         transition: all 0.3s ease;
    //         // opacity: 0;
    //         // max-height: 0;
    //         margin-top: -15%;
    //     }
    //     .assessement-infos {
    //         transition: all 0.3s ease;
    //         margin-top: -8%;
    //         opacity: 1;
    //         max-height: 110px;
    //     }
    // }

    .img-container {
        flex: 0 0 50%;
        width: 100%;
        height: 180px;
        position: relative;
        // overflow: hidden;
        // height: 100%;
        // padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;

        img {
            width: 100%;
            border-radius: 10px;
            object-fit: fill;
            aspect-ratio: 3/2;
            object-fit: contain;
            width: 180px;
            height: 180px;
            // border-radius: 50%;
            // border: 2px solid #2195f352;
            padding: 5%;
            // box-shadow:
            //     20px 20px 60px #bebebe,
            //     -20px -20px 60px #ffffff;
            // mix-blend-mode: color-burn;
        }

        .hover-text {
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            color: #656669;
            text-decoration: underline;
            /* background: rgba( 255,255,255, 0.8);*/
            background-color: transparent;
            border: none;
            font-size: 18px;
            font-weight: 400;
        }
    }

    > :nth-child(2) {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        flex: auto;
    }

    .assessement-name {
        width: 100%;
        font-size: 22px;
        line-height: 2rem;
        font-weight: 600;
        // padding: 0 1rem;
        text-align: center;
        transition: all 0.3s ease;
    }

    .assessement-infos {
        width: 100%;
        opacity: 0;
        max-height: 0;
        transition: all 0.3s ease;

        /*flex: 0 0 25%;*/
        ul {
            text-align: left;
            padding-left: 8%;
            list-style-type: disc;
            list-style: none;

            li {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 1%;
            }
        }
    }
}

// .topAssess {
//     span {
//         font-size: 14px;
//         color: #2196f3;
//         font-weight: 500;
//     }
// }

// margin-top: 10%;

.add-now {
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.85;
    }
}

.delete {
    background: #e5484d !important;
}

.details {
    // border-radius: 5px;
    // color: #2196f3;
    // background: #fff;
    // font-weight: 500;
    // font-size: 14px;
    // line-height: 20px;
    // width: 15%;
    // place-self: center;
    // height: 40px;
    // border-radius: 10px;
    transition: all 0.3s ease;

    &:hover {
        text-decoration: underline;
    }
}

.time {
    color: #000000b6;
}

// .title-question {
//     font-size: 18px;
//     font-weight: 600;
//     text-align: left;
//     width: 100%;
// }

.assessDesc {
    font-size: 14px;
    font-weight: 400;
    padding: 1rem 0;
    text-align: left;
    line-height: 1.2;
}
</style>
